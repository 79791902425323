.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.nav-link {
  font-family: "Schibsted Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
}

.nav-link.active {
  color: #24337a !important;
  font-family: "Schibsted Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
}

.footer {
  color: #24337a !important;
  padding-bottom: 2em;
}

.form-group {
  text-align: left !important;
}

.schibsted-grotesk-400 {
  font-family: "Schibsted Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.schibsted-grotesk-600 {
  font-family: "Schibsted Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.schibsted-grotesk-900 {
  font-family: "Schibsted Grotesk", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
}

.card-header-with-image {
  position: relative;
  padding: 0;
  overflow: hidden;
}

.card-header-with-image .header-content {
  position: relative;
  z-index: 1;
  padding: 20px;
  color: white;
  text-shadow: 2px 2px 2px rgba(0,0,0,0.6); /* Optional: Add text shadow for better visibility */
}

.card-header-with-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../public/DefaultHeader.jpg');
  background-size: cover;
  background-position: center;
  filter: brightness(0.75); /* Optional: Adjust brightness for better text visibility */
  z-index: 0;
}

.header-logo {
  color: #336699;
  font-weight: bolder;
  font-size: calc(10px + 2vmin);
}

.about-text {
  padding: 1em;
  font-weight: 400;
  font-size: calc(12px + 2vmin);
}

.home-hero {
  width: 100vw;
}

.signup-form {
  padding-top: 2%;
  width: 100%;
}

.header-lead {
  color: #ffffff;
  font-weight: bolder;
  /* font-size: calc(10px + 2vmin); */
}

.platform-header {
  color: #24337a;
}

.platform-item {
  /* align-items: start; */
  text-align: left;
}

.contact-tagline {
  color: #24337a;
}

.volunteer-tagline {
  color: #24337a;
}

.form-success {
  color: #24337a !important;
  font-weight: 900;
}

.form-error {
  color: #cc3333 !important;
  font-weight: 900;
}

.social-group {
  padding: 2em;
}

.social-button {
  background-color: #cc3333 !important;
  font-family: 'Schibsted Grotesk';
  font-weight: 900;
  border-radius: 0 !important;
  border-color: #cc3333 !important;
  letter-spacing: 2px;
  padding: 1em;
  transition: background-color 0.3s ease; /* Smooth transition on hover */
  transition: transform 0.3s ease; /* Smooth transition on hover */
}

.social-button:hover {
  border-color: #24337a !important;
  background-color: #24337a !important; /* Change background color on hover */
  transform: scale(1.1);
}